.clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}

.button--light {
  font-size: 12px;
  @include font--proxima-nova-bold;
  padding: 0 32px;
  height: 48px;
  line-height: 4;
  text-align: center;
  display: inline-block;
  border: none;
  text-decoration: none;
  cursor: pointer;
  color: #{$color-black};
  box-shadow: inset 0 0 0 1px #{$color-black};
  background: #{$color-white};
  &:hover {
    text-decoration: underline;
    background: #{$color-white};
    box-shadow: inset 0 0 0 1px #{$color-pink-accent};
    color: #{$color-pink-accent};
  }
}

body {
  .site-content {
    .site-email-signup {
      text-align: center;
      margin-top: 40px;
      &__title {
        font-size: 29px;
        line-height: 1;
        margin-bottom: 2px;
      }
      &__subtitle {
        font-size: 20px;
        margin-bottom: 10px;
        padding-top: 15px;
      }
      &__copy {
        text-align: center;
      }
      &__messages {
        text-align: center;
      }
      &__error {
        color: #{$color-pink-accent};
      }
    }
  }
  .pg_wrapper {
    padding: 0 20px;
    font-size: 14px;
    @include breakpoint($medium-up) {
      font-size: 16px;
      padding: 0 40px 100px;
      margin: auto;
    }
    .title--h2,
    .title--h3 {
      @include font--bodoni-bold;
      text-transform: uppercase;
      font-size: 24px;
      text-transform: uppercase;
    }
    .address-book-info {
      .address_form_container {
        input {
          @include breakpoint($medium-up) {
            width: 50%;
          }
        }
        .field--postcode {
          width: 50%;
          @include breakpoint($medium-up) {
            width: 25%;
          }
        }
      }
    }
    h5 {
      @include font--proxima-nova-bold;
      text-transform: uppercase;
      margin-bottom: 20px;
      font-size: 14px;
      @include breakpoint($medium-up) {
        font-size: 16px;
      }
    }
    .error_messages {
      li {
        font-size: 16px;
        color: #{$color-pink-accent};
      }
    }
    .box-content {
      font-size: 16px;
      margin-bottom: 34px;
    }
    .tab-switcher {
      .title--h5 {
        font-size: 14px;
        @include breakpoint($medium-up) {
          font-size: 16px;
        }
      }
    }
    .content-block {
      overflow: visible;
    }
    .continue-buttons {
      .btn {
        height: 48px;
        font-size: 12px;
        line-height: 4;
        width: 100%;
      }
    }
  }
}
