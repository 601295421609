.site-footer-wrapper {
  .sticky-footer-chat {
    position: fixed;
    right: 20px;
    bottom: 0;
    z-index: 1000;
    width: 0;
    padding: 0;
    cursor: pointer;
    @include breakpoint($medium-up) {
      bottom: 40px;
    }
    &__title {
      &:hover {
        color: #{$color-black};
      }
    }
    .LPMcontainer {
      margin: 0!important;
      padding: 0!important;
      outline: initial;

      // For old chat icon, can be removed after change is in effect
      img {
        position: relative;
      }
      span {
        background: $color-black;
        box-shadow: -25px 0 0 0px $color-black;
        padding: 6px 8px;
        z-index: -1;
        position: relative;
      }
    }
  }
}

body.using-spp-layout {
  .site-footer-wrapper {
    .sticky-footer-chat {
      padding: 0;

      @include breakpoint($med-small-down) {
        bottom: 120px;
      }
      .LPMcontainer {
        transition: all 0.5s;
      }
    }
  }
}
