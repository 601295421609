.product-full {
  &__installment--price {
    .afterpay-paragraph {
      margin-bottom: 0;
      @include breakpoint($small-down) {
        padding-top: 15px;
        padding-bottom: 10px;
      }
      .Clearpay-logo {
        #{$rdirection}: 15px;
        position: absolute;
        vertical-align: bottom !important; // !important used to override the inline styles
      }
      .afterpay-text1 {
        font-size: get-rem(13px);
        @include breakpoint($medium-up) {
          font-size: get-rem(14px);
        }
      }
      .afterpay-link {
        background: $color-white;
        color: $color-black !important; // !important used to override the inline styles
        display: block !important; // !important used to override the inline styles
        font-size: get-rem(13px);
        height: auto;
        text-align: #{$ldirection};
        text-decoration: underline !important; // !important used to override the inline styles
        text-transform: none;
        width: auto;
        @include breakpoint($medium-up) {
          font-size: get-rem(14px);
        }
      }
    }
  }
  &__price-installment-wrapper {
    order: 19;
    text-align: #{$ldirection};
  }
  &__price-klarna-container,
  &__installment--price {
    border: 1px solid $color-medium-grey;
    margin: 15px 10px 10px;
    padding: 14px;
    @include breakpoint($medium-up) {
      margin: 15px 0;
    }
  }
  &__klarna-price-wrapper {
    display: flex;
    justify-content: space-between;
    .klarna-account {
      &__content {
        font-size: get-rem(13px);
        width: 80%;
        @include breakpoint($medium-up) {
          font-size: get-rem(14px);
        }
      }
      &__logo {
        background-image: url('#{$base-theme-path}img/icons/svg/icon--klarna.svg');
        background-repeat: no-repeat;
        background-size: contain;
        color: transparent;
        width: 60px;
      }
    }
  }
}

// Klarna CMS overlay
.klarna-overlay {
  .klarna-information {
    &__header {
      background-image: url('/media/export/cms/checkout/klarna_black.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 65px;
      margin-bottom: 25px;
      text-indent: -999em;
      width: 120px;
    }
    &__list {
      counter-reset: counter;
      margin-bottom: 25px;
      margin-#{$ldirection}: 30px;
    }
    &__list-item {
      align-items: baseline;
      counter-increment: counter;
      display: flex;
      margin-bottom: 10px;
      &::before {
        content: counter(counter) '.';
        flex-basis: 19px;
        font-size: 24px;
        padding-#{$rdirection}: 10px;
      }
    }
    &__sub-text {
      color: $color-dark-grey;
    }
  }
}

.search-wrapper__results {
  .results__products--column {
    @include breakpoint($medium-up) {
      margin: 0;
      float: $ldirection;
      width: 85%;
    }
  }
}

.csspositionsticky {
  .site-header-wrapper {
    top: 0;
  }
}

//Clearpay Popup styles override
#afterpay-modal-overlay {
  #afterpay-modal-modal {
    height: 95%;
    width: 95%;
    @include breakpoint($medium-up) {
      height: 75%;
      width: 70%;
    }
  }
}

#power_review_container {
  .sign-in-page {
    .email_check {
      float: #{$rdirection};
    }
  }
}

.site-content {
  .right {
    .tab-pane {
      display: flex;
    }
  }
}
