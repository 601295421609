// Appointment booking
@mixin appt_booking {
  abbr,
  abbr[title] {
    border: none;
    cursor: default;
    text-decoration: none;
  }
  // Responsive skedgeme iframe
  #skedgemeIframe.ios-device {
    margin: 0;
    width: 50%;
    .device-mobile & {
      width: 50%;
    }
  }
  body {
    &.section-book-appointment {
      .appt-book {
        .text-link-1 {
          text-transform: uppercase;
          color: $color-pink-popup;
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
  }
  // Collection formatter - main container
  .appt-book {
    margin: 0 auto 16px;
    position: relative;
    text-align: center;
    &-page-header-content,
    &-content-header {
      .section-header {
        width: 100%;
        margin-top: 3px;
        margin-bottom: 3px;
        &__inner {
          font-family: $font--heading;
          font-size: 40px;
          line-height: 62px;
          @include breakpoint($medium-up) {
            font-size: 50px;
            line-height: 50px;
          }
        }
        &:before {
          content: unset;
        }
      }
    }
    &-sections-container {
      position: relative;
    }
    &-section {
      position: relative;
      width: 100%;
      &-content {
        position: relative;
        width: 100%;
        margin-bottom: 40px;
      }
    }
    .button.disabled,
    input[type="submit"].disabled,
    input[type="button"].disabled {
      color: $color-black;
      background-color: $color-gray;
      cursor: default;
    }
    .basic-responsive {
      position: relative;
      display: block;
      font-size: 18px;
      a {
        margin-top: 2px;
        @include breakpoint($medium-up) {
          margin-top: 0;
        }
      }
    }
    .touch &,
    .touchevents & {
      select {
        background-position: 97% 46%;
        padding-#{$rdirection}: 35px;
      }
    }
    // Appointment Booking page header
    &-page-header {
      margin: 0 auto 16px;
      position: relative;
      color: $color-black;
      text-decoration: none;
      display: block;
      max-width: $max-width;
      text-align: center;
      &-bg {
        display: block;
        width: 100%;
        height: auto;
        &.bg-img-mobile {
          display: none;
        }
      }
      &-content {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: 58%;
        // resetting position to static to avoid "0"-like height of block
        .inner {
          position: absolute;
          width: 100%;
          height: auto;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
        h2,
        h3,
        h4,
        p {
          text-align: center;
          margin-bottom: 0;
          line-height: 1.2;
          font-size: 20px;
        }
        h2 {
          font-family: $font--text;
          font-size: 40px;
          letter-spacing: 9px;
          text-transform: uppercase;
        }
        h3 {
          font-size: 27px;
        }
        h4 {
          font-size: 25px;
          line-height: 1.3;
        }
        .multi {
          white-space: nowrap;
        }
        .multi--mobile-inline {
          display: inline-block;
          @include breakpoint($medium-up) {
            display: block;
          }
        }
      }
    }
    &-content-header {
      margin: 20px auto;
      .service-select & {
        margin-bottom: 0;
        padding: 0 10px;
        @include breakpoint($medium-up) {
          margin: 20px auto;
          padding: unset;
        }
      }
      p {
        line-height: 16px;
        @include breakpoint($medium-up) {
          font-size: 14px;
        }
      }
      .book-now-bar {
        padding-top: 17px;
      }
    }
    .location-select-section,
    .service-select,
    .appointment-select,
    .confirmation {
      .section-header {
        &__inner {
          font-family: $font--heading;
          @include breakpoint($medium-up) {
            font-size: 50px;
            line-height: 50px;
          }
        }
        &:before {
          content: unset;
        }
      }
    }
    .page-header {
      &__title {
        font-family: $font--bauer-bodoni;
        font-size: 39px;
        font-weight: bold;
        letter-spacing: 7px;
        padding-#{$ldirection}: 90px;
        text-align: $ldirection;
        text-transform: uppercase;
      }
      &__subtitle {
        padding-#{$ldirection}: 90px;
        text-align: $ldirection;
        p {
          font-family: $font--proxima-nova;
          font-size: 24px;
          line-height: 1.3;
        }
      }
    }
    .appointment-cancellation {
      width: 100%;
      position: relative;
      & .cancel-content,
      & .error-content {
        display: none;
      }
      .button-container {
        display: block;
        margin-top: 40px;
        text-align: center;
      }
    }
  }
  .appt-book-overlay {
    display: none;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    bottom: 0;
    #{$rdirection}: 0;
    background: $color-white;
    background-color: $color-white;
    z-index: 100;
    .overlay-content-container {
      padding: 32px 35px;
      position: absolute;
      display: block;
      text-align: center;
      background-color: $color-pink-2;
      border: 1px solid $color-gold;
      z-index: 1;
      width: 428px;
      height: auto;
      min-height: 185px;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
      .overlay-close {
        position: absolute;
        display: block;
        #{$rdirection}: 0;
        top: 0;
        background: transparent url("/media/export/cms/appointment_booking/common/overlay_close_btn.gif") no-repeat;
        width: 37px;
        height: 32px;
        cursor: pointer;
        z-index: 3;
        overflow: hidden; // for img replacement
        text-indent: 100%; // for img replacement
        white-space: nowrap; // for img replacement
        margin-right: 0%;
      }
      .overlay-content {
        display: block;
        position: relative;
      }
    }
    &.appt-book-services-overlay .overlay-content-container,
    &.appt-book-book-conflict-overlay .overlay-content-container {
      margin-top: -95px;
      width: 90%;
      margin: auto;
      #{$ldirection}: 5%;
      top: 40%;
      @include breakpoint($medium-up) {
        width: 428px;
        top: 50%;
        #{$ldirection}: 50%;
        margin-#{$ldirection}: -214px;
      }
    }
    &.my-appointments-overlay .overlay-content-container {
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -95px;
      width: 330px;
      margin-#{$ldirection}: -165px;
      @include breakpoint($medium-up) {
        width: 428px;
        margin-#{$ldirection}: -214px;
      }
      #confirm-cancel,
      #appt-canceled {
        .title,
        .message,
        .appointment-details {
          text-align: $ldirection;
        }
        .button {
          width: 100%;
        }
        .message,
        .appointment-details {
          margin-bottom: 30px;
        }
      }
      #confirm-cancel {
        .reschedule {
          text-decoration: underline;
          text-transform: uppercase;
          color: $color-black;
          padding: .75em 1em;
          display: block;
          width: fit-content;
          margin: auto;
          &:hover {
            color: $color-black-hover;
          }
        }
      }
      #appt-canceled {
        .appointment-details {
          margin-top: 25px;
          span {
            display: block;
          }
          .name {
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .no-rgba .appt-book-overlay {
    background: transparent url(/media/export/cms/appointment_booking/common/overlay_tint.png) repeat;
  }
  .pg_wrapper {
    #content {
      max-width: $max-width;
      padding: 0;
      .appt-book {
        width: auto;
        overflow: hidden;
        position: relative;
        margin: unset;
        .appt-book-content-header-title {
          margin-bottom: 25px;
          @include breakpoint($medium-up) {
            margin-bottom: 28px;
            text-align: $ldirection;
            font-size: 21px;
            letter-spacing: inherit;
          }
        }
        .my-appointments {
          padding: 0 10px;
          width: 100%;
          position: relative;
          @include breakpoint($medium-up) {
            padding: unset;
          }
          .appointments-container {
            position: relative;
            width: auto;
            &.past {
              height: auto;
              overflow: unset;
              @include breakpoint($medium-up) {
                clear: both;
              }
              #past-appt-head {
                text-align: center;
                margin: 30px 0;
                .link {
                  font-size: 18px;
                  line-height: 31px;
                  vertical-align: middle;
                  padding-top: 5px;
                  padding-bottom: 3px;
                  border-bottom: 2px solid $color-black;
                  &:hover {
                    border-color: $color-pink;
                  }
                }
                @include breakpoint($medium-up) {
                  text-align: $ldirection;
                }
              }
              #past-appt-body {
                display: none;
              }
              &.expanded {
                #past-appt-head {
                  .link {
                    padding-bottom: 5px;
                    border: unset;
                    @include breakpoint($medium-up) {
                      border-bottom: unset;
                    }
                  }
                }
                #past-appt-body {
                  display: block;
                }
              }
            }
          }
          .appointment-container {
            position: relative;
            width: 100%;
            overflow: hidden;
            margin-bottom: 25px;
            border: 1px solid $color-gold;
            background: $color-pink-2;
            .left,
            .right {
              display: block;
              @include breakpoint($medium-up) {
                display: inline-block;
              }
            }
            .left {
              @include breakpoint($medium-up) {
                float: $ldirection;
              }
            }
            .right {
              @include breakpoint($medium-up) {
                float: $rdirection;
                text-align: $ldirection;
              }
            }
            .appt-service-picture {
              @include breakpoint($medium-up) {
                width: 47%;
              }
              @include breakpoint($xxlarge-up) {
                width: 50%;
              }
            }
            .appointment-details {
              position: relative;
              overflow: hidden;
              width: 92%;
              margin: 20px 4% 20px;
              padding-top: 1.5%;
              @include breakpoint($medium-up) {
                margin-#{$ldirection}: 3%;
                margin-#{$rdirection}: 3%;
                width: 47%;
              }
              @include breakpoint($xxlarge-up) {
                width: 44%;
              }
              .your-lessons {
                margin-bottom: 20px;
                .left,
                .right {
                  display: inline-block;
                }
                .left {
                  @include breakpoint($medium-up) {
                    float: unset;
                  }
                }
                .right {
                  float: $rdirection;
                }
                .name {
                  font-family: $font--text;
                  width: 58%;
                  text-align: $ldirection;
                  font-weight: 700;
                  text-transform: uppercase;
                  font-size: 16px;
                }
                .appointments-buttons {
                  color: $color-black;
                  text-transform: uppercase;
                  text-align: $rdirection;
                  margin-#{$ldirection}: 5%;
                  font-size: 12px;
                  width: 37%;
                  @include breakpoint($medium-up) {
                    width: 32%;
                    margin-#{$ldirection}: 10%;
                  }
                  @include breakpoint($xxlarge-up) {
                    width: 38%;
                    margin-#{$ldirection}: 4%;
                  }
                  .change-appt-btn,
                  .cancel-appt-btn {
                    color: $color-pink-accent;
                    padding-#{$ldirection}: 5px;
                    padding-#{$rdirection}: 5px;
                    &:hover {
                      color: $color-pink-accent-hover;
                    }
                  }
                }
              }
              .values {
                font-family: $font--text;
                .duration {
                  text-transform: uppercase;
                }
              }
              .date-time {
                margin-bottom: 20px;
                .appt-date {
                  display: block;
                }
              }
              .values,
              .location,
              .date-time {
                text-align: $ldirection;
              }
              .location {
                // adding style for zoom link in account page
                .zoom-meeting {
                  margin-top: 20px;
                }
                .zoom-link-node {
                  word-break: break-all;
                }
                span,
                a {
                  display: block;
                  color: $color-black;
                  float: left;
                  clear: both;
                }
                a:hover {
                  color: $color-black-hover;
                }
                &-direction-link {
                  margin-bottom: 15px;
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          .appt-booking-links {
            margin-top: 35px;
            @include breakpoint($medium-up) {
              overflow: auto;
              margin-top: 45px;
            }
            h4 {
              margin: 0 0 28px;
              font-family: $font--proxima-nova;
              font-size: 18px;
              letter-spacing: 0.1em;
            }
            a.button {
              color: $color-pink-button-text;
              @include breakpoint($medium-up) {
                @include swap_direction (padding, 0.8em 0.45em 0.95em 0.7em);
                letter-spacing: 0.25em;
                float: $ldirection;
              }
            }
          }
          #my-appointments-past {
            margin-top: 35px;
            border-top: 1px solid $color-gray;
            @include breakpoint($medium-up) {
              margin-top: 45px;
            }
          }
          #appt-book-no-appts-section {
            .appt-book-no-appts-section-title {
              margin-bottom: 20px;
              @include breakpoint($medium-up) {
                text-align: $ldirection;
                font-size: 21px;
                letter-spacing: inherit;
              }
            }
            .appt-book-no-appts-content {
              position: relative;
              &-bgimg {
                background-repeat: no-repeat, repeat;
                background-size: cover;
                width: 100%;
                background-position: $rdirection;
                height: 300px;
                background-color: $color-pink-2;
                background-blend-mode: multiply;
                @include breakpoint($medium-up) {
                  background-position: center;
                  background-blend-mode: unset;
                }
                @include breakpoint($xxlarge-up) {
                  height: 280px;
                }
              }
              .no-appts-content {
                position: absolute;
                top: 0;
                height: 100%;
                padding: 30px 8px 10px;
                @include breakpoint($medium-up) {
                  padding-top: 40px;
                  top: 0;
                  #{$ldirection}: 5%;
                  width: 50%;
                  text-align: $ldirection;
                }
                @include breakpoint($xxlarge-up) {
                  padding-top: 42px;
                }
                &-title,
                &-subtitle {
                  color: $color-black;
                }
                &-title {
                  font-size: 32px;
                  @include breakpoint($medium-up) {
                    font-size: 40px;
                    line-height: 1;
                  }
                }
                &-subtitle {
                  margin-top: 25px;
                  @include breakpoint($xxlarge-up) {
                    margin-top: 15px;
                    letter-spacing: inherit;
                  }
                }
                #appt-book-btn {
                  margin-top: 70px;
                  background-color: $color-black;
                  color: $color-white;
                  @include breakpoint($medium-up) {
                    margin-top: 40px;
                  }
                  @include breakpoint($xxlarge-up) {
                    margin-top: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@if $virtual-appointment {
  .appt-book {
    .appointment-booking-main-collection {
      &__section {
        .appointment-booking-review {
          &-virtual {
            &__details {
              width: 100%;
            }
          }
        }
      }
    }
    .virtual__appointment--btn {
      padding: 0 50px;
      margin: 10px 0;
      font-family: $font--bauer-bodoni;
      letter-spacing: 0.6px;
      font-size: 11px;
      height: 34px;
      &:hover,
      &:active,
      &:visited,
      &:focus {
        background: $color-black;
      }
    }
    .appointment-booking-location {
      &__header {
        .virtual__appt {
          &__text {
            @include breakpoint($medium-up) {
              font-weight: bold;
            }
          }
          &__booking {
            &__subheader {
              @include breakpoint($medium-up) {
                margin: 36px 0 12px;
              }
              margin: 20px 0 5px;
            }
            &--btn {
              @include button--black-pink-alt;
              @include breakpoint($medium-up) {
                padding: 0 70px;
                height: 40px;
              }
              padding: 0 60px;
              height: 42px;
              &:hover {
                color: $color-pink-button-text;
              }
            }
          }
        }
        .appt__booking__line-break {
          &--pc {
            @include breakpoint($medium-up) {
              display: block;
            }
          }
          &--mobile {
            @include breakpoint($medium-up) {
              display: inline;
            }
            display: block;
          }
        }
        .connector__text {
          @include breakpoint($medium-up) {
            margin-bottom: 30px;
          }
          font-family: $font--proxima-nova;
          margin: 15px 0 20px;
        }
      }
    }
  }
}
