// intentionally left blank
$appt_book: true;
// Virtual Appointment Enabling variable
$virtual-appointment: true !default;

.site-content {
  .spp-reviews {
    #spp-reviews-display {
      .pr-rd-author-location {
        display: none;
      }
    }
  }
}

// *
// * PDP Refresh 2024
// *
$cr24: true;
