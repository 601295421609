input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
  &.adpl--processed {
    &.active,
    &.adpl__mode-label,
    &:focus {
      border-color: #{$color-black};
      color: #{$color-black};
    }
  }
}

.adpl {
  input,
  select,
  .selectBox {
    &:required,
    &.selectBox-required {
      box-shadow: none;
      &.error,
      &:invalid,
      &.selectBox-invalid {
        &:empty,
        &[value=''],
        &:not(:checked),
        &.empty {
          border-color: #{$color-normal-grey};
        }
        box-shadow: none;
      }
    }
  }
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  select {
    border-color: #{$color-normal-grey};
    &:focus,
    &.active,
    &.adpl__mode-label {
      & + label:before,
      & + .adpl-label:before {
        background: #{$color-white};
        line-height: 1;
        padding: 0 1.5px;
        -webkit-transform: translateY(-155%) translateY(-0.5px) scale(0.7, 0.7);
        transform: translateY(-155%) translateY(-0.5px) scale(0.7, 0.7);
      }
    }
    &:required {
      &.error,
      &.error[value=''],
      &:invalid,
      &:invalid[value=''] {
        &.empty,
        &:empty,
        &:not(:checked) {
          &:focus {
            border-color: #{$color-pink-accent};
          }
        }
        &:focus {
          border-color: #{$color-pink-accent};
        }
      }
    }
    & + .adpl-label,
    & + label {
      overflow: visible;
    }
  }
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  textarea {
    height: 3em;
    font-size: 16px;
    border-radius: 0;
    padding: 13px 12px 12px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #{$color-normal-grey};
    & + label {
      display: block;
      font-size: 1em;
      height: 3em;
      line-height: 3;
      cursor: text;
    }
  }
}
